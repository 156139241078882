@font-face {
	font-family: "Poppins-Light";
	src: url("../fonts/Poppins-Light.ttf");
}
@font-face {
	font-family: "Poppins-Regular";
	src: url("../fonts/Poppins-Regular.ttf");
}
@font-face {
	font-family: "Poppins-Medium";
	src: url("../fonts/Poppins-Medium.ttf");
}

body {
	margin: 0px;
	padding: 0px;
	background: #e8e8e8;
}
h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0px;
	padding: 0px;
	font-family: "Poppins-Medium";
}
p,
a,
span {
	margin: 0px;
	padding: 0px;
	font-family: "Poppins-Regular";
}

ul {
	list-style: none;
}
a {
	text-decoration: none;
}
:root {
	--title_color: #282828;
	--content_color: #7c7c7c;
	--btn_color: #1877f2;
	--menu_color: #7c7c7c;
	--grey_color: #858484;
	--logo_color: #ffa800;
}

.button {
	border: none;
}
.text-left {
	text-align: left;
}
.text-center {
	text-align: center;
}
.text-right {
	text-align: right;
}
.bold {
	font-weight: bold;
}
