.singup {
	background: #e8e8e8;
}
.col_left,
.col_right,
.singup.row {
	padding: 0px;
	margin: 0px;
}
.singup .col_left_box {
	background: linear-gradient(138.97deg, #1877f2 0%, #70a8f0 101.98%);
	padding: 40px;
}

.singup .col_left .singup_header {
	display: flex;
}
.singup .col_left .singup_header > div {
	margin-top: auto;
	margin-bottom: auto;
	width: 100%;
}
.singup .col_left .singup_header .left h2 {
	font-weight: 700;
	font-size: 33.3157px;
	line-height: 50px;
	color: #ffffff;
}
.singup .col_left .singup_header .left h2:nth-child(2) {
	font-style: normal;
	font-weight: 600;
	font-size: 39.5624px;
	line-height: 59px;
	color: #ffa800;
}
.singup .col_left .singup_footer {
	margin-top: 15px;
}
.singup .col_left .singup_footer h3 {
	font-weight: 500;
	font-size: 22px;
	line-height: 25px;
	color: #ffffff;
}
.singup .col_left .singup_footer h3:nth-child(2) {
	font-size: 30px;
	line-height: 25px;
	margin-top: 26px;
	margin-bottom: 50px;
}
.singup .col_left .singup_footer h3:nth-child(2) span {
	font-size: 14px;
	line-height: 16px;
}

.singup .col_left .singup_footer .singup_description {
	font-size: 14px;
	line-height: 21px;
	color: #ffffff;
	display: flex;
	margin-bottom: 18px;
}
.singup .col_left .singup_footer .singup_description p {
	margin-top: auto;
	margin-bottom: auto;
	font-weight: 400;
	font-size: 14px;
	line-height: 21px;
	color: #ffffff;
}
.singup .col_left .singup_footer .singup_description span {
	border-bottom: 4px solid #ffa800;
	width: 16px;
	display: block;
	border-radius: 1000px;
	display: block;
	border-radius: 2px;
	margin-right: 10px;
	margin-top: auto;
	margin-bottom: auto;
}
.singup .col_left .singup_footer .singup_button {
	margin-top: 50px;
	background: rgba(255, 255, 255, 0.2);
	border-radius: 4px;
	display: flex;
	padding: 24px;
	border: 1px solid #fff;
}
.singup .col_left .singup_footer .singup_button .left {
	width: 59%;
	margin-top: auto;
	margin-bottom: auto;
}
.singup .col_left .singup_footer .singup_button .right {
	margin-top: auto;
	margin-bottom: auto;
}
.singup .col_left .singup_footer .singup_button p {
	width: 100%;
	font-size: 18px;
	line-height: 25px;
	color: #ffffff;
	font-family: "Poppins-Medium";
}
.singup .col_left .singup_footer .singup_button .right p span {
	font-size: 14px;
}
/* .singup .col_left .singup_footer .singup_button p:nth-child(2) {
	text-align: right;
} */
.singup .col_left .singup_footer .singup_button p:nth-child(2) span {
	font-size: 14px;
	font-family: "Poppins-Regular";
}
.singup .col_left .singup_footer .img {
	margin-top: -40px;
	margin-left: -38px;
}

/* Singup_right */

.singup .col_right .col_right_box_header {
	width: 80%;
	margin-left: auto;
	margin-right: auto;
	display: flex;
}
.singup .col_right .col_right_box_header img {
	margin-right: 6px;
	margin-top: auto;
	margin-bottom: auto;
}

.singup .col_right .col_right_box_header span {
	margin-top: auto;
	margin-bottom: auto;
	font-weight: 500;
	font-size: 15px;
	line-height: 18px;
	color: #1877f2;
	font-family: "Poppins-Medium";
}
.singup .col_right .col_right_box_header {
	margin-top: 40px;
}
.singup .col_right .col_right_box {
	background: #ffffff;
	border-radius: 8px;
	width: 80%;
	padding: 40px;
	margin-left: auto;
	margin-right: auto;
	margin-top: 20px;
}
.singup .col_right .col_right_box h2 {
	font-size: 26px;
	line-height: 39px;
	color: #383838;
	text-align: center;
	margin-bottom: 15px;
}
.singup .col_right .col_right_box .noaccount {
	margin-bottom: 15px;
}
.singup .col_right .singup_form .form_item {
	margin-bottom: 20px;
}
.singup .col_right .singup_form label {
	display: block;
	font-size: 14px;
	line-height: 16px;
	color: #192746;
	margin-bottom: 4px;
}
.singup .col_right .singup_form input {
	background: #ffffff;
	border: 1.15442px solid #e2e8f0;
	border-radius: 5px;
	width: 100%;
	height: 42.25px;
	padding: 2px 10px;
	outline: none;
	color: #747474;
}
.singup .col_right .singup_form button {
	background: #1877f2;
	border-radius: 6px;
	outline: none;
	border: none;
	width: 100%;
	padding: 15px 0px;
	color: #fff;
	font-size: 16px;
	line-height: 16px;
	font-family: "Poppins-Medium";
}
.singup .col_right .singup_form .singin p,
.singup .col_right .singup_form .singin span {
	font-family: "Poppins-Medium";
	font-size: 15px;
	line-height: 22px;
	color: #383838;
	text-align: center;
}
.singup .col_right .singup_form .singin span {
	color: var(--btn_color);
}
.singup .col_right_box p,
.singup .col_right_box span {
	font-weight: 500;
	font-size: 15px;
	line-height: 22px;
	font-family: "Poppins-Medium";
	color: #383838;
	text-align: center;
}
.singup .col_right_box span {
	color: var(--btn_color);
}

/* singin css */

.forget_password {
	text-align: right;
	cursor: pointer;
	margin-top: -10px;
}
.singin .col_right .col_right_box,
.forget .col_right .col_right_box,
.reset .col_right .col_right_box {
	max-width: 500px;
}

.sign_up_textarea textarea {
	width: 100%;
	min-height: 150px;
	padding: 8px;
	border-radius: 8px;
	margin-top: 8px;
}

.radio_sign_up input {
	width: auto !important;
	height: auto !important;
}
@media screen and (max-width: 991px) {
	.singin .col_right .col_right_box,
	.forget .col_right .col_right_box,
	.reset .col_right .col_right_box {
		width: 50%;
	}
}
@media screen and (max-width: 768px) {
	.singin .col_right .col_right_box,
	.forget .col_right .col_right_box,
	.reset .col_right .col_right_box {
		width: 60%;
	}
}
@media screen and (max-width: 670px) {
	.singin .col_right .col_right_box,
	.forget .col_right .col_right_box,
	.reset .col_right .col_right_box {
		width: 80%;
	}
}
@media screen and (max-width: 470px) {
	.singin .col_right .col_right_box,
	.forget .col_right .col_right_box,
	.reset .col_right .col_right_box {
		width: 85%;
	}
	.singin .col_right .col_right_box,
	.forget .col_right .col_right_box,
	.reset .col_right .col_right_box {
		padding: 40px 15px;
	}
}

/* Signup responsive start */
@media screen and (max-width: 1085px) {
	.singup .col_left .singup_header .left h2 {
		font-size: 25px;
		line-height: 35px;
	}
}
@media screen and (max-width: 992px) {
	.singup .col_left .singup_header .left h2 {
		font-size: 18px;
		line-height: 26px;
	}
	.singup .col_right .col_right_box {
		padding: 40px 15px;
	}
	.singup .col_left .singup_footer h3 {
		font-size: 18px;
		line-height: 25px;
	}
	.singup .col_left .singup_footer h3:nth-child(2) {
		font-size: 24px;
		line-height: 25px;
	}
	.singup .col_left .singup_footer .singup_button p {
		font-size: 14px;
		line-height: 25px;
	}
}
@media screen and (max-width: 875px) {
	.singup .col_right .col_right_box {
		padding: 40px 15px;
	}
	.singup .col_left_box {
		padding: 40px 15px;
	}
	.singup .col_right .col_right_box {
		width: 90%;
	}
}
@media screen and (max-width: 795px) {
	.singup .col_left .singup_header {
		display: block;
	}
	.singup .singup_header .right,
	.singup .singup_header .left {
		text-align: center;
	}
}
@media screen and (max-width: 721px) {
	.singup .col_left {
		width: 50%;
	}

	.singup .col_left .singup_footer .singup_button {
		padding: 24px 12px;
	}
}
@media screen and (max-width: 679px) {
	.singup {
		display: block;
	}
	.singup .col_left {
		width: 100%;
	}
	.singup .col_left .singup_footer .singup_button {
		padding: 24px;
	}
}
@media screen and (max-width: 365px) {
	.singup .col_left .singup_footer .singup_button {
		padding: 24px 5px;
	}
	.singup .col_left .singup_footer .singup_button p:nth-child(1) {
		width: 54%;
	}
	.singup .col_left .singup_footer .singup_button p:nth-child(2) {
		width: 42%;
	}
	.singup .col_left .singup_footer .img {
		width: 100%;
	}
}

/* Below are stripe css */

/* label {
	color: #6b7c93;
	font-weight: 300;
	letter-spacing: 0.025em;
	margin-top: 16px;
	display: block;
}

button {
	white-space: nowrap;
	border: 0;
	outline: 0;
	display: inline-block;
	height: 40px;
	line-height: 40px;
	padding: 0 14px;
	box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
	color: #fff;
	border-radius: 4px;
	font-size: 15px;
	font-weight: 600;
	text-transform: uppercase;
	letter-spacing: 0.025em;
	background-color: #6772e5;
	text-decoration: none;
	-webkit-transition: all 150ms ease;
	transition: all 150ms ease;
	margin-top: 10px;
}

button:hover {
	color: #fff;
	cursor: pointer;
	background-color: #7795f8;
	transform: translateY(-1px);
	box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
}

button[disabled] {
	opacity: 0.6;
}

input,
select {
	display: block;
	border: none;
	font-size: 18px;
	margin: 10px 0 20px 0;
	max-width: 100%;
	padding: 10px 14px;
	box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
	border-radius: 4px;
	background: white;
	color: #424770;
	letter-spacing: 0.025em;
	width: 500px;
}

input::placeholder {
	color: #aab7c4;
}

.result,
.error {
	font-size: 16px;
	font-weight: bold;
	margin-top: 10px;
	margin-bottom: 20px;
}

.error {
	color: #e4584c;
}

.result {
	color: #666ee8;
}

 
.StripeElement,
.StripeElementIdeal,
.StripeElementP24,
.StripeElementEPS,
.StripeElementFpx {
	display: block;
	margin: 10px 0 20px 0;
	max-width: 500px;
	padding: 10px 14px;
	box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
	border-radius: 4px;
	background: white;
}

.StripeElement--focus,
.StripeElementIdeal--focus,
.StripeElementP24--focus,
.StripeElementEPS--focus,
.StripeElementFpx--focus {
	box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px, rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
	-webkit-transition: all 150ms ease;
	transition: all 150ms ease;
}

.StripeElement.loading {
	height: 41.6px;
	opacity: 0.6;
}

.StripeElementIdeal,
.StripeElementP24,
.StripeElementEPS,
.StripeElementFpx {
	padding: 0;
} */
