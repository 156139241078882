.accordion {
	margin-top: 30px;
}
.accordion_item {
	border-radius: 16px;
	background: #fff;
	box-shadow: 0px 0px 15px 0px rgba(37, 143, 103, 0.15);
	padding: 15px;
	margin-bottom: 15px;
}
.accordion_item h2,
.accordion_item p {
	-moz-user-select: none;
	-khtml-user-select: none;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.accordion_item h2 {
	font-size: 20px;
	line-height: 36px;
	color: #383838;
	width: 100%;
	margin-right: 10px;
	margin-top: auto;
	margin-bottom: auto;
}
.accordion_item h2.active,
.accordion_item .fa-minus {
	color: var(--btn_color);
}
.accordion_item p {
	font-weight: 400;
	font-size: 16px;
	line-height: 25px;
	color: #7c7c7c;
	margin-top: 20px;
}
.accourdion_top {
	display: flex;
	cursor: pointer;
}

.accourdion_top i {
	margin-top: auto;
	margin-bottom: auto;
}
.accourdion_bottom {
	display: none;
	transition: all 1s ease-out;
	height: 0% !important;
}

.accourdion_bottom.active {
	display: block;
	transition: all 2s ease-in;
	height: 100% !important;
}

@media screen and (max-width: 767px) {
	.faq_heading p {
		font-size: 16px;
	}
	.accordion_item h2 {
		font-size: 18px;
		line-height: 26px;
	}
}
