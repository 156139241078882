.post {
	padding: 24px;
	margin-top: 30px;
	border: 1px solid #e4e4e4;
	border-radius: 5px;
	background: #fff;
	margin-bottom: 30px;
	/* overflow: hidden; */
}

.post .post_header {
	display: flex;
}
.post .post_header .header_left {
	width: 100%;
	display: flex;
}

.post .post_header .header_left .name {
	display: flex;
}
.post .post_header .header_left .name .right {
	margin-top: auto;
	margin-bottom: auto;
}

.post .post_header .header_left .name .left .dot {
	width: 4px;
	height: 4px;
	border-radius: 50%;
	background: var(--btn_color);
	margin-top: auto;
	margin-bottom: auto;
	margin-left: 5px;
	margin-right: 5px;
}
.post .post_header .header_left .name a {
	display: flex;
}

.post .post_header .header_left .img,
.post .post_header .header_left .name,
.post .post_header .header_left .time {
	margin-top: auto;
	margin-bottom: auto;
	margin-right: 8px;
}
.post .post_header .header_left .img img {
	width: 49px;
	height: 49px;
	border-radius: 50%;
}

.post .post_header .header_left .name {
	font-weight: 500;
	font-size: 18px;
	line-height: 27px;
	color: #383838;
	font-family: "Poppins-Medium";
}

.post .post_header .header_left .time {
	font-weight: 300;
	font-size: 14px;
	line-height: 21px;
	color: rgba(56, 56, 56, 0.6);
}
.post .post_header .header_right {
	width: 100%;
	text-align: right;
}
.post .post_header .header_right_btn.btn_1 {
	margin-right: 10px;
	background: #fff;
	color: #1877f2;
}
.post .post_header .header_right_btn {
	font-size: 14px;
	line-height: 21px;
	color: #fff;
	width: 95px;
	height: 38px;
	background: #1877f2;
	border: 1px solid #e4e4e4;
	border-radius: 5px;
}

.post .post_content h3,
.post .post_content h3 a {
	font-weight: 500;
	font-size: 20px;
	line-height: 25px;
	color: var(--title_color);
	margin-top: 10px;
	margin-bottom: 10px;
}

.post .post_description,
.post .post_description p {
	font-weight: 400 !important;
	font-size: 16px !important;
	line-height: 25px !important;
	color: var(--content_color) !important;
	margin-bottom: 15px !important;
	font-family: "Poppins-Regular" !important;
}

.post .post_description a {
	color: var(--btn_color) !important;
	font-weight: bold;
	font-family: "Poppins-Medium" !important;
}
.post .reply_btn {
	background-color: var(--btn_color) !important;
	border: var(--btn_color) !important;
	color: #fff;
}
.post .post_img img {
	max-width: 100%;
	border-radius: 9px;
	max-height: 310px;
	margin-top: 10px;
	margin-right: 10px;
}

.post .post_content .read_more {
	padding-bottom: 18px;
	border-bottom: 1px solid #d1d1d1;
	font-weight: 500;
	font-size: 16px;
	line-height: 25px;
	color: #1877f2;
	margin-top: 18px;
	text-align: center;
	cursor: pointer;
}
.post .post_content .read_more_img {
	margin-left: 3px;
}
.post_tag {
	display: flex;
	flex-wrap: wrap;
}
.post_tag p {
	padding: 1px 6px;
	background: #bbbbbb;
	border-radius: 4px;
	margin-right: 10px;
	margin-top: 20px;
	color: #ffffff;
	cursor: pointer;
}
/* Footer of Post */
.post .post_footer {
	display: flex;
	margin-top: 20px;
}
.post .post_footer .footer_left {
	display: flex;
}
.post .post_footer > div {
	width: 100%;
}
.post .post_footer .footer_left .box {
	background: #ffffff;
	border: 1.5px solid #bbbbbb;
	padding: 6px 0px;
	border-radius: 8px;
	width: 81px;
	text-align: center;
	margin-right: 16px;
	margin-top: auto;
	margin-bottom: auto;
	cursor: pointer;
}
.post .post_footer .footer_left .box img {
	margin-right: 12px;
}
.post .post_footer .footer_right {
	text-align: right;
}
.post .post_footer .footer_right .footer_btn {
	font-weight: 500;
	font-size: 14px;
	line-height: 21px;
	color: #ffa800;
	width: 95px;
	height: 38px;
	border: 1px solid #ffa800;
	border-radius: 5px;
	background: #fff;
}

.post .post_footer .footer_left .box.reteweet img {
	margin-top: -3px;
}

.post .post_comment_form {
	margin-top: 40px;
	position: relative;
}
.post .post_comment_form input,
.post .post_comment_form textarea,
.post .post_comment_form .content_edit {
	background: #ffffff;
	border: 1px solid #bbbbbb;
	border-radius: 8px;
	/* height: 48px; */
	padding: 2px 10px;
	position: relative;
	width: 100%;
	padding-right: 95px;
}
.post .post_comment_form .content_edit {
	height: 60px;
	/* margin-top: -30px; */
}
.post .post_comment_form .content_edit:focus {
	outline: 2px solid var(--btn_color);
}

[contenteditable][placeholder]:empty:before {
	content: attr(placeholder);
	position: absolute;
	color: gray;
	background-color: transparent;
}

.post .placeholder_span {
	position: relative;
	z-index: 4;
}
.post .post_comment_form img.cmt_btn_img {
	position: absolute;
	top: 13px;
	right: 20px;
	cursor: pointer;
}
.post .post_comment_form .img_prev {
	text-align: right;
	margin-right: 20px;
	position: relative;
}
.post .post_comment_form .img_prev img {
	width: 50px;
	height: 50px;
}
.post .post_comment_form .img_prev i.img_change {
	position: absolute;
	top: 2px;
	right: -5px;
	z-index: 1;
	background: #fff;
	padding: 2px 4px;
	border-radius: 50%;
	font-size: 18px;
	cursor: pointer;
}

.post .user_comment img.comment_img {
	max-width: 100%;
	max-height: 100%;
	margin-top: 10px;
}
.post .post_comment_form i.img_box {
	position: absolute;
	top: 13px;
	right: 60px;
	cursor: pointer;
	font-size: 22px;
	color: var(--btn_color);
}

/* comments */
.user .name span.name {
	color: var(--title_color);
}
.user .name span.time {
	font-weight: 300;
	font-size: 14px;
	line-height: 21px;
	color: rgba(56, 56, 56, 0.6);
}

.post .post_comments {
	margin-top: 36px;
}
.post .post_comments .user {
	display: flex;
	margin-top: 15px;
}
.post .post_comments .user .name {
	width: 100%;
}

.post .post_comments .user .img {
	margin-right: 12px;
	position: relative;
}

.post .post_comments .user .img img {
	width: 42px;
	height: 42px;
	border-radius: 50%;
}
.post .post_comments .user .img .line {
	border-left: 1px solid #bbbbbb;
	height: 80%;
	position: absolute;
	left: 21px;
	/* top: 50px; */
}
.post .post_comments .comment_like {
	display: flex;
	margin-top: 13px;
}
.post .post_comments .comment_like .box span {
	font-weight: 400;
	font-size: 13.75px;
	line-height: 21px;
	color: #bbbbbb;
}
.post .post_comments .comment_like .box {
	background: #ffffff;
	border: 1.5px solid #bbbbbb;
	padding: 2px 0px;
	border-radius: 8px;
	width: 60px;
	text-align: center;
	margin-right: 16px;
	margin-top: auto;
	margin-bottom: auto;
	cursor: pointer;
}
.post .post_comments .comment_like .box.reply_icon {
	width: 95px;
}
.post .post_comments .comment_like .box img {
	margin-right: 6px;
}

.post .post_comments .reply {
	margin-top: 28px;
}

/* Polls start */
.poll {
	margin-top: 20px;
}
.poll_bar {
	display: flex;
	margin-bottom: 15px;
}
.poll_bar .left {
	margin-top: auto;
	margin-bottom: auto;
	width: 100%;
	margin-top: auto;
	margin-bottom: auto;
	border-radius: 5px;
	padding: 8px 0px;
	margin-right: 20px;
}
.poll_bar .left p {
	padding-left: 12px;
	color: #fff;
}
.poll_bar .right {
	margin-top: auto;
	margin-bottom: auto;
}
.poll .result span {
	margin-right: 10px;
	color: #7c7c7c;
	font-size: 16px;
	line-height: 25px;
}
.poll .result span:nth-child(2) {
	color: #383838;
}

.post .post_header .header_left .name a {
	color: #383838;
}
.post .post_footer .footer_left .box.like,
.post .post_comments .comment_like .box.like {
	border: 1.8px solid var(--btn_color);
	color: var(--btn_color);
}
.post .post_footer .footer_left .box.dislike,
.post .post_comments .comment_like .box.dislike {
	border: 1.8px solid #ff7575;
	color: #ff7575;
}
.post .post_comments .comment_like .box.like span {
	color: var(--btn_color);
}
.post .post_comments .comment_like .box.dislike span {
	color: #ff7575;
}
.post .poll_question .question h3 {
	font-weight: 500;
	font-size: 20px;
	line-height: 25px;
	color: var(--title_color);
	margin-top: 10px;
	margin-bottom: 10px;
}
.post .poll_question .answer_1 label,
.post .poll_question .answer_2 label {
	padding-left: 10px;
}

.custom_alert {
	z-index: -1;
}

.post_content_wrapper.Retweet .post_content {
	padding: 20px 15px 20px 40px;
	box-shadow: 0 12px 61px -7px rgba(4, 6, 15, 0.1);
	border-radius: 8px;
}

.post_content_wrapper.Retweet .retweet_content {
	margin-top: 10px;
	margin-bottom: 10px;
}

.feed_retweet_wrapper {
	margin-top: 15px;
	padding: 20px;
	border: 1px solid #bbbbbb;
	border-radius: 8px;
}

.retweet_header_right {
	margin-top: auto;
	margin-bottom: auto;
}
.retweet_header_right a {
	background-color: var(--btn_color);
	color: #fff;
	padding: 10px 20px;
	border-radius: 8px;
}
/* Customer ADMIN CSS */
.post .edit {
	text-align: right;
}
.post .edit i {
	cursor: pointer;
}
.post .edit_menu {
	right: 90px;
	position: absolute;
	box-shadow: 0 3px 15px rgb(0 0 0 / 35%);
	background: #fff;
	margin-top: 5px;
	border-radius: 5px;
	padding: 5px 5px;
}

.post .edit_menu span {
	display: block;
	text-align: left;
	cursor: pointer;
	padding: 2px 10px;
}
.hidden {
	display: none;
}

.post_content_wrapper .expand {
	cursor: pointer;
	color: var(--btn_color);
}

.post_content_wrapper .top_comment {
	margin-top: 15px;
}
.post_content_wrapper .view_comment button {
	margin-top: 15px;
	background-color: var(--btn_color) !important;
	border: var(--btn_color) !important;
	color: #fff;
}

.post .post_footer .footer_left .box.super_like img,
.comment_like .box.super_like img {
	margin-top: -2px;
}

.retweet_header_right.mobile {
	display: none;
}
.retweet_header_right.mobile a {
	display: inline-block;
	margin-top: 10px;
}

.search_user_list {
	position: absolute;
	height: 300px;
	z-index: 1;
	min-width: 250px;
	width: max-content;
	overflow: auto;
	padding: 5px;
	background: #fff;
	border: 2px solid var(--btn_color);
	border-radius: 5px;
}
.search_user_list .user_item {
	display: flex;
	padding-left: 5px;
	padding-top: 5px;
	padding-bottom: 5px;
	cursor: pointer;
	border-bottom: 2px solid #ccc;
}

.search_user_list .user_item .user_img {
	margin-top: auto;
	margin-bottom: auto;
	margin-right: 5px;
}
.search_user_list .user_item .user_name {
	margin-top: auto;
	margin-bottom: auto;
}

.hoverflow {
	overflow: unset;
}

/* Responsive start */
@media screen and (max-width: 767px) {
	.post .post_header .header_left .name {
		display: block;
	}
	.post .post_header .header_left .name .left .dot {
		display: none;
	}
	.post .post_header .header_left .name a {
		display: block;
	}
}

@media screen and (max-width: 690px) {
	.post .post_header .header_left .name {
		font-size: 16px;
	}
	.post .post_header .header_right_btn {
		width: 65px;
		height: 30px;
	}
	.post .post_content h3 {
		font-size: 18px;
	}
	.post .post_description p {
		font-size: 14px;
	}
	.post_tag p {
		font-size: 14px;
	}
	.post .post_content .read_more {
		font-size: 14px;
	}
	.post_footer .footer_left span {
		font-size: 14px;
	}
	.post .post_footer .footer_left .box {
		padding: 3px 0px;
		width: 65px;
	}
	.post .post_footer .footer_right .footer_btn {
		width: 65px;
		height: 30px;
	}
	.post .post_comment_form img {
		top: 10px;
	}
	.post .post_comment_form input {
		height: 42px;
	}
	.post .post_comment_form img {
		right: 20px;
	}
}

@media screen and (max-width: 600px) {
	.post .post_footer .footer_right {
		text-align: left;
	}
	.post .post_footer .footer_left {
		display: block;
	}
	.post .post_footer .footer_left .row_1 {
		display: flex;
	}
	.post .post_footer .footer_left .row_2 {
		margin-top: 10px;
		display: flex;
	}
}

@media screen and (max-width: 490px) {
	/* .post .post_header {
		flex-wrap: wrap;
	}
	.post .post_header .header_left {
		width: 100%;
	} */

	/* .post .post_header .header_right {
		width: 100%;
		text-align: left;
		margin-left: 58px;
		margin-top: -15px;
	}
	.post .post_header .header_left .name {
		margin-top: unset;
		margin-bottom: unset;
	}
	.post .post_header .header_left .time {
		margin-top: 4px;
		margin-bottom: unset;
	} */
	.retweet_header_right.desktop {
		display: none;
	}
	.retweet_header_right.mobile {
		display: block;
	}
	.post .post_comments .comment_like {
		flex-wrap: wrap;
	}
	.post .post_comments .comment_like > div {
		margin-bottom: 10px !important;
	}
}
@media screen and (max-width: 450px) {
	.post .post_header {
		display: block;
	}
	.post .post_header .header_right {
		margin-top: 10px;
		text-align: left;
	}

	/* 
	.post .post_header .header_left .name a {
		display: flex;
	}
	.post .post_header .header_left .name .left .dot {
		display: block;
	}
	.post .post_header .header_left .name {
		display: flex;
		margin-top: unset;
	} */
	/* .post .post_header .header_right {
		text-align: left;
		margin-top: -20px;
		margin-left: 58px;
	} */
}

@media screen and (max-width: 400px) {
	.post {
		padding: 14px;
	}
	.post .post_comment_form img.cmt_btn_img {
		right: 12px;
	}
	.post .post_comment_form i.img_box {
		right: 46px;
	}
	.post .post_comment_form input,
	.post .post_comment_form textarea {
		padding-right: 75px;
	}
}

/* @media screen and (max-width: 420px) {
	.post .post_footer .footer_left .box {
		margin-right: 5px;
		width: 52px;
	}
	.post_footer .footer_left span {
		font-size: 12px;
	}
	.post .post_footer .footer_left .box img {
		max-width: 18px;
	}
} */
