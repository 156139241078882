.profile-row {
	background: #fff;
	padding: 0px 0px 50px 0px;
	margin-left: 30%;
}
.profile-row .profile_bg {
	height: 200px;
	background-repeat: no-repeat;
	background-size: cover;
}
.profile-row .profile_top_items {
	margin-top: -85px;
	padding: 0px 35px;
	display: flex;
}
.profile-row .profile_bottom_items {
	padding: 0px 35px;
}
.profile-row .profile_top_items .left {
	width: 100%;
}
.profile-row .profile_bottom_items .left .follower p {
	display: inline-block;
	padding-right: 15px;
	color: #383838;
	font-size: 17px;
	font-family: "Poppins-Medium";
}

.profile-row .profile_bottom_items .left .follower p span {
	display: inline-block;
	color: #7c7c7c;
}
.profile-row .profile_bottom_items .left .follower h1 {
	font-size: 26px;
	line-height: 39px;
	color: #383838;
	margin-bottom: 8px;
	margin-top: 10px;
}
.profile-row .profile_top_items .right {
	width: 100%;
	margin-top: auto;
	margin-bottom: auto;
	color: var(--btn_color);
	font-family: "Poppins-Medium";
	font-size: 17px;
	cursor: pointer;
	text-align: right;
}
.profile-row .profile_top_items img {
	border-radius: 50%;
	width: 160px;
	height: 160px;
	border: 4px solid #ffffff;
}
.profile-row .profile_bottom_items .profile_menu {
	margin-top: 40px;
	display: flex;
	flex-wrap: wrap;
}
.profile-row .profile_bottom_items .profile_menu > p {
	margin-right: 20px;
	cursor: pointer;
}
.profile-row .profile_bottom_items .profile_menu p.active {
	border-bottom: 3px solid var(--btn_color);
	border-radius: 3px;
}
.profile_items_wrapper {
	border: 1px solid #f1f1f1;
	padding-bottom: 2px;
}

.profile-row .feed_wrapper {
	padding: 0px 35px;
}
.profile-row .profile_top_items .right {
	margin-top: 112px;
}
.profile-row .profile_top_items .right p,
.profile-row .profile_top_items .right a,
.following_row .follow_box p.button {
	display: inline;
	padding: 6px 16px;
	border: 1px solid var(--btn_color);
	border-radius: 5px;
}
.profile_bottom_items h1 {
	font-size: 26px;
	line-height: 39px;
	font-family: "Poppins-Medium";
	color: #383838;
}
.portfolio_wrapper {
	margin-top: 10px;
	padding-left: 30px;
	padding-right: 30px;
}
.single_profile_wrapper .img_box {
	width: 200px;
}
.single_profile_wrapper .img_box img {
	max-width: 100%;
	margin-top: 10px;
}

/* Follow box */

.following_row {
	display: flex;
	flex-wrap: wrap;
	margin-top: 20px;
	padding: 12px;
}
.following_row .follow_box {
	margin-right: 10px;
	margin-bottom: 15px;
	padding: 10px;
	border: 1px solid #e4e4e4;
	text-align: center;
	width: 23%;
	background: #e8e8e8;
	border-radius: 5px;
}
.following_row .follow_box img {
	width: 60px;
	height: 60px;
	border-radius: 50%;
}
.following_row .follow_box p.button {
	margin-bottom: 15px;
	display: inline-block;
	cursor: pointer;
}
.following_row .follow_box p.name {
	margin-bottom: 15px;
	margin-top: 15px;
}
.following_row .follow_box p.name a {
	color: #000;
}

.follower .point {
	background: green;
	color: #fff !important;
	border-radius: 8px;
	padding-left: 5px !important;
	padding-right: 5px !important;
}
.follower .point span {
	color: #fff !important;
	display: inline-block;
}

.trade_wrapper table {
	margin-top: 10px;
}

.table-responsive.history thead {
	background: var(--btn_color);
	color: #ffff;
}

.table-responsive.history th,
.table-responsive.history td {
	font-family: "Poppins-Regular";
}

.table-responsive.history .running_trade {
	color: green;
}
.table-responsive.history .exit_trade {
	color: red;
}
.table-responsive.history .gain_percentage {
	color: green !important;
}
.table-responsive.history .gain_percentage.negetive {
	color: red !important;
}

@media screen and (max-width: 767px) {
	.profile-row .feed_wrapper {
		padding: 0px 15px;
	}
	.profile-row .post {
		padding: 24px 15px;
	}
}
@media screen and (max-width: 476px) {
	.profile-row .profile_top_items,
	.profile-row .profile_bottom_items {
		padding: 0px 12px;
	}
	.profile-row .profile_top_items .right p {
		padding: 4px 10px;
	}
	.profile-row .profile_bottom_items .profile_menu > p {
		margin-right: 10px;
	}
	.profile_bottom_items h1 {
		font-size: 22px;
	}
}
@media screen and (max-width: 567px) {
	.post_comments .name .user_data span:nth-child(1) {
		font-size: 14px;
	}
	.post_comments .name .user_data span {
		font-size: 12px;
	}
	.post_comments .name .user_data span {
		font-size: 12px;
	}
	.post_comments .name .user_data {
		margin-top: 8px;
	}

	.post_comments .reply .user_comment {
		margin-left: -45px;
		margin-top: 15px;
	}
	.post_comments .user_comment p {
		font-size: 14px;
	}
	.post_comments .reply .comment_like {
		margin-left: -45px;
	}

	.post_comments .reply .comment_like .box {
		margin-right: 8px;
	}
}

@media screen and (max-width: 399px) {
	.follower .point {
		font-size: 15px !important;
	}
	.post .post_comments .comment_like .box {
		width: 50px;
	}
	.post .post_comments .comment_like .box:nth-child(3) {
		width: 70px;
	}
	.post .post_comments .user .img {
		margin-right: 5px;
	}
}
