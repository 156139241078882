.admin_wrapper {
	background-color: #fff;
}

.admin_wrapper .admin_top {
	background-color: #fff;
	z-index: 10;
	box-shadow: 0 0 10px 1px rgb(68 102 242 / 5%);
	transition: 0.3s;
	display: flex;
	padding: 20px 20px;
}
.admin_wrapper .admin_top .right {
	text-align: right;
	width: 100%;
}
.admin_wrapper .left .logo p:nth-child(1) {
	color: var(--btn_color);
	font-family: "Poppins-Medium";
}
.admin_wrapper .left .logo p:nth-child(2) {
	color: #ffa800;
	font-family: "Poppins-Medium";
}

.admin_body {
	display: flex;
}

.admin_body .right {
	width: 80%;
}
.admin_body .left {
	background-color: #fff;
	z-index: 10;
	box-shadow: 0 0 10px 1px rgb(68 102 242 / 5%);
	width: 20%;
	height: 100vh;
	position: fixed;
}
.admin_body .right {
	margin-left: 20%;
}
.admin_body .left .logo {
	padding-left: 10px;
	margin-bottom: 15px;
	margin-top: 20px;
}
.admin_body .left .admin_menu .menu_wrapper {
	display: flex;
	cursor: pointer;
}
.admin_body .left .admin_menu .admin_menu_item span {
	display: block;
	margin-top: auto;
	margin-bottom: auto;
	padding-left: 10px;
}
.admin_body .left .admin_menu .admin_menu_item a {
	padding: 6px 12px;
	cursor: pointer;
	display: block;
}
.admin_body .left .admin_menu .admin_menu_item a {
	color: #000;
}
.admin_body .left .admin_menu a.no_toggle_main {
	width: 100%;
	display: block;
	padding: 6px 0px;
	color: #000;
}
.admin_body .left .admin_menu .admin_menu_item a.main i {
	margin-left: 10px;
}
.admin_body .left .admin_menu .main_menu:hover,
.admin_body .left .admin_menu .toggle_menu:hover,
.admin_body .left .admin_menu .no_toggle_main:hover,
.admin_body .left .admin_menu .admin_menu_item .admin_sub_menu:hover {
	color: #000;
	background-color: rgba(0, 0, 0, 0.04);
}
.admin_wrapper .left .admin_menu .admin_sub_menu a {
	display: block;
	padding: 6px 12px;
	cursor: pointer;
	display: block;
	margin-left: 10px;
	width: 100%;
}

/* Form design */
.admin_form {
	padding: 20px;
	width: 80%;
	height: 100vh;
}
.admin_form .input_items .input_group {
	margin-bottom: 20px;
}
.admin_form .input_items .input_group label {
	display: block;
	font-size: 14px;
	line-height: 16px;
	color: #192746;
	margin-bottom: 10px;
}
.admin_form .input_items .input_group textarea {
	width: 100%;
	background: #ffffff;
	border: 1.15442px solid #e2e8f0;
	border-radius: 5px;
	padding: 4px;
	outline: none;
	color: #747474;
}

.admin_form_user_post_edit {
	height: 100% !important;
}

.user_post_edit_admin .admin_body .right {
	overflow: hidden;
}

.admin_form .input_items .input_group input,
.admin_form .input_items .input_group select {
	background: #ffffff;
	border: 1.15442px solid #e2e8f0;
	border-radius: 5px;
	width: 100%;
	height: 42.25px;
	padding: 2px 10px;
	outline: none;
	color: #747474;
}

.admin_form .input_items .input_group button {
	background: #1877f2;
	border-radius: 6px;
	outline: none;
	border: none;
	width: 100%;
	padding: 15px 0px;
	color: #fff;
	font-size: 16px;
	line-height: 16px;
	font-family: "Poppins-Medium";
}

.admin_table {
	width: 100%;
	padding: 20px;
}
.comment_admin_img {
	max-width: 100px;
	max-height: 100px;
}
.admin_product {
	width: 100%;
	padding: 20px;
}

.admin_product {
	margin-top: 20px;
}

.admin_product img {
	max-width: 100%;
	margin-bottom: 12px;
}
.admin_product h3 {
	margin-bottom: 5px;
}
.admin_product .product_col {
	margin-bottom: 20px;
	z-index: 10;
	box-shadow: 0 0 10px 1px rgb(68 102 242 / 12%);
	padding: 20px;
	border-radius: 5px;
	max-width: 31.5%;
	margin-right: 10px;
}
.admin_product .product_col a {
	padding: 8px 15px;
	background-color: green;
	color: #fff;
	border-radius: 5px;
	margin-top: 15px;
	display: block;
	text-align: center;
}

.cursor {
	cursor: pointer;
}
.admin_body_header {
	display: flex;
}
.admin_body_header .left_header {
	margin-top: auto;
	margin-bottom: auto;
	width: 100%;
}
.admin_body_header {
	margin-bottom: 12px;
}
.admin_body_header .right_header {
	margin-top: auto;
	margin-bottom: auto;
	width: 100%;
	text-align: right;
}
.admin_body_header .right_header input {
	padding: 6px 6px;
	border: 1px solid var(--btn_color);
	outline: none;
	border-radius: 5px;
	width: 250px;
}
/* Pagination of admin */

.page-link {
	color: #171b26;
	cursor: pointer;
}
.page-item.active .page-link {
	background-color: var(--btn_color);
	border-color: var(--btn_color);
}
.page-item.active .page-link:hover {
	color: #fff !important;
}

.result_item_wrapper {
	position: relative;
}
.result_item_wrapper {
	margin-top: 5px;
}
.result_item_wrapper .result_item {
	position: absolute;
	background-color: #fff;
	border-radius: 8px;
	border: 1px solid #ccc;
	overflow: auto;
	max-height: 300px;
	min-height: 150px;
	min-width: 230px;
	z-index: 1;
}

.result_item_wrapper .result_item .item {
	border-bottom: 1px solid var(--btn_color);
	padding: 5px;
	cursor: pointer;
}

.result_item_wrapper .result_item::-webkit-scrollbar {
	width: 5px;
	border-radius: 10px;
}

.result_item_wrapper .result_item::-webkit-scrollbar-track {
	box-shadow: inset 10px 10px 10px #eaeaea;
}

.result_item_wrapper .result_item::-webkit-scrollbar-thumb {
	border: 4px solid transparent;
	border-radius: 8px;
	background-color: var(--btn_color);
}

.trade_box:first-child {
	margin-top: 20px;
}
.trade_box {
	padding: 15px;
	border: 2px solid var(--btn_color);
	border-radius: 8px;
	margin-bottom: 20px;
}
.trade_box .input_group:last-child {
	margin-bottom: 0px;
}

.trade_box .input_group i {
	position: absolute;
	top: -5px;
	right: 0px;
	padding: 3px 5px 3px 5px;
	background-color: var(--btn_color);
	color: #fff;
	border-radius: 50%;
	cursor: pointer;
}

.trade_box .first_input_group {
	position: relative;
}

.exit_trade input {
	margin-right: 5px;
}
.exit_trade input,
.exit_trade label {
	cursor: pointer;
}

.notice_form textarea {
	min-height: 150px;
}

.back_btn a {
	background: #1877f2;
	border-radius: 6px;
	outline: none;
	border: none;
	width: 100%;
	padding: 15px 0px;
	color: #fff;
	font-size: 16px;
	line-height: 16px;
	font-family: "Poppins-Medium";
	display: block;
	text-align: center;
}

@media screen and (min-width: 992px) {
	.logo_wrapper .icon {
		display: none;
	}
}
@media screen and (max-width: 991px) {
	.admin_body {
		display: block;
	}
	.admin_body .left {
		position: unset;
		height: auto;
		width: 100%;
	}

	.admin_body .right {
		width: 100%;
		margin-left: unset;
	}
	.logo_wrapper {
		display: flex;
		padding-right: 20px;
		position: relative;
	}
	.logo_wrapper .icon {
		font-size: 24px;
		margin-top: auto;
		margin-bottom: auto;
	}
	.logo_wrapper a {
		width: 100%;
		display: block;
	}
	.logo_wrapper .icon {
		width: 100%;
		text-align: right;
	}
	.admin_menu {
		position: absolute;
		background: #fff;
		z-index: 10;
		box-shadow: 0 0 10px 1px rgb(68 102 242 / 12%);
		border-radius: 5px;
		padding: 10px;
		right: 10px;
		top: 64px;
	}
	.admin_top .right p {
		text-align: center;
	}
	.admin_form {
		margin: auto;
	}
	.admin_product .product_col {
		max-width: 350px;
	}
}

@media screen and (max-width: 667px) {
	.admin_form {
		width: 100%;
	}
}
