.header {
	background-color: #fff;
	display: flex;
	padding: 10px 0px;
	position: fixed;
	top: 0px;
	width: 100%;
	z-index: 1;
}

.header .header_left p:nth-child(1) {
	color: #1877f2;
	font-family: "Poppins-Medium";
	font-size: 19.0312px;
	font-weight: bold;
}
.header .header_left p:nth-child(2) {
	color: #ffa800;
	font-family: "Poppins-Medium";
	font-size: 22.5995px;
	font-weight: bold;
	margin-top: -8px;
}
.header .header_middle {
	display: flex;
}
.header .header_middle .search {
	z-index: 10;
	margin-top: auto;
	margin-bottom: auto;
	display: flex;
}
.header .header_middle .search i {
	margin-right: 5px;
	font-size: 18px;
	color: #7c7c7c;
}
.header .header_middle .search span {
	font-size: 14px;
	color: #7c7c7c;
}
.header .header_middle .enter {
	margin-left: -90px;
}
.header .bottom_search {
	display: none;
}
.header .header_middle input,
.header .bottom_search input {
	width: 389px;
	height: 46px;
	background: #ffffff;
	border: 1px solid #d1d1d1;
	outline: 1px solid #d1d1d1;
	border-radius: 8px 0px 0px 8px;
	box-sizing: border-box;
	padding: 0px 10px;
}
.header .header_right {
	text-align: right;
}
.header .header_right a {
	background: #1877f2;
	color: #fff;
	padding: 9px 24px;
	border: 1px solid #1877f2;
	border-radius: 6px;
	display: inline-block;
	cursor: pointer;
}

.menu_section .menu_col {
	position: fixed;
	top: 73px;
	height: 100%;
	z-index: 1;
}
.menu {
	background: #ffffff;
	padding-bottom: 15px;
	border: 1px solid #f1f1f1;
}
.menu .menu_box {
	background: #ffffff;
}
.menu_col .extra {
	height: 100vh;
	background: #fff;
}
.menu .menu_box .menu_row {
	padding: 14px 0px 14px 15px;
	display: flex;
}
.menu .menu_box .menu_row a {
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	color: var(--menu_color);
	margin-top: auto;
	margin-bottom: auto;
}
.menu .menu_box .menu_row:nth-child(1) img {
	filter: brightness(0.4);
}
.menu .menu_box .menu_row img {
	margin-top: auto;
	margin-bottom: auto;
	margin-right: 7px;
}

.menu .menu_box .menu_row.active {
	background: var(--btn_color);
}

.menu .menu_box .menu_row.active a {
	color: #fff;
}
.menu .menu_box .menu_row.active img {
	filter: brightness(2);
}

.tag_box {
	background: #fff;
	padding-top: 30px;
	padding-left: 15px;
	padding-right: 15px;
	padding-bottom: 20px;
	height: 320px;
	overflow: auto;
	padding-bottom: 50px;
}

.menu_section .menu_col::-webkit-scrollbar,
.tag_box::-webkit-scrollbar,
.notfication_list::-webkit-scrollbar {
	width: 5px;
	border-radius: 10px;
}

.menu_section .menu_col::-webkit-scrollbar-track,
.tag_box::-webkit-scrollbar-track,
.notfication_list::-webkit-scrollbar-track {
	box-shadow: inset 10px 10px 10px #eaeaea;
}

.menu_section .menu_col::-webkit-scrollbar-thumb,
.tag_box::-webkit-scrollbar-thumb,
.notfication_list::-webkit-scrollbar-thumb {
	border: 4px solid transparent;
	border-radius: 8px;
	background-color: var(--btn_color);
}

.tag_box h3 {
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	color: #383838;
}
.tag_box a {
	display: block;
	padding: 7px 0px;
	color: var(--menu_color);
}
.body_col {
	margin-left: 29%;
	margin-top: 85px;
}
.header_row .right_col {
	margin-top: auto;
	margin-bottom: auto;
}
.logout.menu_row {
	cursor: pointer;
}
.header .search_click {
	background: var(--btn_color);
	padding: 0px 10px;
	color: #fff;
	vertical-align: middle;
	padding-top: 10px;
	margin-left: -3px;
	border-radius: 0px 4px 5px 0px;
	cursor: pointer;
	border: 1px solid var(--btn_color);
	outline: 1px solid var(--btn_color);
}
.tag_title {
	font-family: "Poppins-Medium";
	font-weight: 500;
}

.notification_icon {
	margin-top: auto;
	margin-bottom: auto;
	cursor: pointer;
	text-align: right;
}
.notification_badge span.bell {
	position: relative;
}
.notification_badge i.fa-bell {
	position: relative;
	font-size: 24px;
	cursor: pointer;
	color: var(--btn_color);
}
.notification_badge span.text {
	background: red;
	font-size: 14px;
	position: absolute;
	left: 2px;
	top: -20px;
}
.notfication_list {
	position: absolute;
	background: var(--btn_color);
	width: 300px;
	z-index: 2000;
	color: #fff;
	top: 20px;
	left: -270px;
	height: 320px;
	overflow: auto;
}
.notfication_list a {
	color: #fff;
}
.notfication_list a:hover {
	filter: brightness(200%);
}
.notfication_list .notification_item {
	text-align: left;
}
.notfication_list .notification_item a {
	border-bottom: 1px solid #ccc;
	padding: 10px 10px;
	display: block;
}
.notfication_list .notification_item .time p {
	font-size: 12px;
}
.notfication_list .notification_item:last-child a {
	border-bottom: none;
	padding-bottom: 5px;
}
.notfication_list::-webkit-scrollbar-thumb {
	border: 4px solid transparent;
	border-radius: 8px;
	background-color: #000;
}
.header_row .right_col {
	display: flex;
	justify-content: right;
}
.header .header_right {
	text-align: right;
	margin-top: auto;
	margin-bottom: auto;
}
.notification_icon {
	margin-right: 20px;
}
@media screen and (min-width: 1500px) {
	.menu_section .menu_col {
		max-width: 350px;
	}
}
@media screen and (max-width: 991px) {
	.header_btn {
		display: none !important;
	}
	.menu_row.tag_row {
		padding-top: 5px !important;
		padding-bottom: 5px !important;
	}
	.menu_col {
		height: auto !important;
		z-index: 100 !important;
	}
	.header .container,
	.menu_section .container {
		max-width: 95%;
	}
	.menu_section .menu_col {
		width: 25%;
	}
	.menu_section .body_col {
		margin-left: 25%;
		width: 75%;
	}
}
@media screen and (max-width: 801px) {
	.header .container,
	.menu_section .container {
		max-width: 95%;
	}
	.menu_section .menu_col {
		width: 25%;
	}
	.menu_section .body_col {
		margin-left: 25%;
		width: 75%;
	}
}
@media screen and (max-width: 991px) {
	.header {
		z-index: 100;
	}
	.menu {
		height: unset;
	}
	.menu_section .row {
		display: block;
	}
	.menu_section .menu_col {
		position: fixed;
		background: #fff;
		z-index: 10;
		box-shadow: 0 0 10px 1px rgb(68 102 242 / 12%);
		border-radius: 5px;
		padding: 10px;
		right: 10px;
		top: 60px;
		width: 185px;
		overflow: auto;
		max-height: 350px !important;
	}
	.menu {
		border: unset;
	}
	.tag_box {
		background-color: unset;
	}
	.menu_section .body_col {
		width: 100%;
		margin-left: 0px;
	}
	.header .header_left {
		width: 100%;
		margin-left: 0px;
	}
	.header .header_right i.fa-bars {
		font-size: 24px;
	}
}

@media screen and (max-width: 780px) {
	.header .header_middle {
		margin-left: 25px;
	}
}
@media screen and (max-width: 730px) {
	.header_row .left_col {
		max-width: 30%;
	}
	.header_row .right_col {
		max-width: 50%;
	}
	.header_row .right_col {
		max-width: 10%;
	}

	.header .header_middle .bottom_search {
		justify-content: center;
	}
	.header .header_middle .enter {
		width: 100%;
	}
}

@media screen and (max-width: 730px) {
	.header .search_click {
		margin-top: 10px;
		height: 35px;
		padding-left: 10px;
		padding-right: 10px;
		padding-top: 3px;
	}
	.custom_alert {
		margin-top: 130px;
	}

	.header_row .left_col {
		max-width: 100%;
	}
	.header .middle_col {
		display: none;
	}
	.header_row .right_col {
		max-width: 100%;
	}

	.header .bottom_search {
		display: block;
	}
	.header .bottom_search input {
		width: 100%;
		height: 35px;
		margin-top: 10px;
		margin-bottom: 10px;
		box-sizing: border-box;
	}
	.header .header_middle {
		justify-content: center;
		margin-left: 12px;
	}
	.body_col {
		margin-top: 140px;
	}
}

@media screen and (max-width: 385px) {
	.notfication_list {
		left: -225px;
		width: 250px;
	}
}
