.single_profile_wrapper .profile_info,
.single_profile_wrapper .protfolio_info {
	margin-bottom: 30px;
	background: #fff;
	padding: 25px;
	border-radius: 8px;
}

.single_profile_wrapper .profile_info h2,
.single_profile_wrapper .protfolio_info h2 {
	font-size: 24px;
	margin-bottom: 10px;
}
.single_profile_wrapper .profile_info button,
.single_profile_wrapper .protfolio_info button.update {
	margin-top: 10px;
	background-color: var(--btn_color);
	color: #fff;
}
.single_profile_wrapper .protfolio_info a.add_porfolio {
	margin-top: 10px;
	margin-bottom: 10px;
	background-color: var(--btn_color);
	color: #fff;
}
.single_profile_wrapper .protfolio_info .portfolio_group {
	margin-top: 10px;
	padding: 15px 10px;
	margin-bottom: 15px;
	box-shadow: 0 0 10px 1px rgb(68 102 242 / 12%);
	border-radius: 5px;
}
.single_profile_wrapper .protfolio_info .close {
	text-align: right;
}

.single_profile_wrapper .protfolio_info .close i {
	font-size: 24px;
	border: 1px solid #ccc;
	border-radius: 50%;
	width: 32px;
	height: 32px;
	padding-right: 5px;
	padding-top: 2px;
	cursor: pointer;
}
.single_profile_wrapper .form-group {
	margin-bottom: 10px;
}
