.banner {
	display: flex;
	background: linear-gradient(90deg, #1877f2 0%, #3d8ffa 58.56%, #509cff 100%);
	border: 1px solid #e4e4e4;
	border-radius: 5px;
	padding: 14px 22px;
}
.banner .banner_left,
.banner .banner_right,
.banner .banner_center {
	margin-top: auto;
	margin-bottom: auto;
}

.banner .banner_left {
	margin-right: 20px;
	width: 20%;
}
.banner .banner_center {
	text-align: center;
	width: 50%;
}
.banner .banner_center p {
	font-size: 18px;
	line-height: 25px;
	color: #ffffff;
}
.banner .banner_right {
	justify-content: end;
	width: 30%;
}
.banner .banner_right a {
	font-size: 16px;
	line-height: 24px;
	color: #1877f2;
	background-color: #fff;
	padding: 12px;
	border: 1px solid #cee1ff;
	border-radius: 8px;
}
.banner .banner_right a img {
	margin-right: 10px;
}

.header_right_btn {
	font-weight: 500;
	font-size: 14px;
	line-height: 21px;
	color: #1877f2;
}

.write_post {
	padding: 24px;
	margin-top: 30px;
	margin-bottom: 30px;
	border: 1px solid #e4e4e4;
	border-radius: 5px;
	background: linear-gradient(90deg, #1877f2 0%, #3d8ffa 58.56%, #509cff 100%);
	display: flex;
	color: #fff;
}
.write_post .banner_left {
	text-align: center;
}
.write_post .left {
	margin-top: auto;
	margin-bottom: auto;
	width: 80%;
}
.write_post .right {
	margin-top: auto;
	margin-bottom: auto;
	text-align: right;
	width: 20%;
}

@media screen and (max-width: 1199px) {
	.banner .banner_right {
		width: 40%;
		text-align: right;
	}
}
@media screen and (max-width: 750px) {
	.banner {
		flex-wrap: wrap;
	}
	.banner .banner_center {
		width: 75%;
	}
	.banner .banner_right {
		width: 100%;
		text-align: center;
		margin-top: 10px;
		margin-bottom: 15px;
	}
}
@media screen and (max-width: 650px) {
	.banner {
		display: block;
	}
	.banner .banner_left,
	.banner .banner_center,
	.banner .banner_right {
		text-align: center;
		margin-bottom: 10px;
		width: 100%;
	}
	.banner .banner_right {
		margin-bottom: 20px;
		margin-top: 30px;
	}
	.write_post .left {
		width: 70%;
	}
	.write_post .right {
		width: 30%;
	}
}
@media screen and (max-width: 650px) {
	.write_post {
		display: block;
	}
	.write_post .left {
		width: 100%;
		text-align: center;
	}
	.write_post .right {
		width: 100%;
		text-align: center;
		margin-top: 10px;
	}
}
