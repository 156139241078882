.model {
	background: rgba(77, 77, 77, 0.65);
	width: 100%;
	height: 100vh;
	position: fixed;
	top: 0px;
	overflow: scroll;
	z-index: 10;
}
.model > div {
	position: unset;
}
.model .form_main .form {
	position: absolute;
	top: 5%;
	left: 0px;
	margin: auto;
	right: 0px;
	width: 639px;
	background: #ffffff;
	border-radius: 11px;
	padding: 30px;
}
.model .form_main .form .input_items {
	margin-top: 10px;
}
.model .form_main .form .input_items label {
	display: block;
	font-weight: 500;
	font-size: 14px;
	line-height: 21px;
	color: #383838;
}
.model .form_main .form .input_items input,
.model .form_main .form .input_items select {
	background: #ffffff;
	border: 1px solid #bbbbbb;
	border-radius: 4px;
	width: 579px;
	height: 42px;
	margin-top: 5px;
	padding: 0px 6px;
}
.model .form_main .form .input_items .content_edit:focus,
.model .form_main .form .input_items input:focus {
	outline-color: var(--btn_color);
}

.hoverflow {
	overflow: hidden;
}
.model .form_main .form .input_items .content_edit {
	margin-top: 5px;
	width: 100%;
	height: 100px;
	padding: 2px 6px;
	border: 1px solid #bbbbbb;
}
.model .form_main .form .input_items_description {
	position: relative;
}
.model .form_main .form .input_items .limit {
	font-weight: 400;
	font-size: 13px;
	line-height: 20px;
	color: #bbbbbb;
}
.model .form_main .form .form_btn button {
	margin-top: 12px;
	background: #1877f2;
	border: 1px solid #1877f2;
	border-radius: 6px;
	outline: none;
	width: 100%;
	color: #ffffff;
	padding: 10px 0px;
	font-family: "Poppins-Medium";
}
.model .form_main .form .form_btn button {
	margin-top: 12px;
	background: #1877f2;
	border: 1px solid #1877f2;
	border-radius: 6px;
	outline: none;
	width: 100%;
	color: #ffffff;
	padding: 10px 0px;
}
.model .form_main .form_header {
	display: flex;
}
.model .form_main .form_header p {
	margin-top: auto;
	margin-bottom: auto;
	width: 100%;
	font-size: 18px;
	line-height: 25px;
	color: #383838;
	font-family: "Poppins-Medium";
}
.model .form_main .form_header i {
	margin-top: auto;
	margin-bottom: auto;
	text-align: right;
	width: 100%;
	cursor: pointer;
	font-size: 24px;
}

.model::-webkit-scrollbar {
	width: 0px;
	border-radius: 10px;
}
.model .input_items .img_box {
	width: 200px;
	margin-top: 10px;
}
.model .input_items .img_box img {
	width: 100%;
}
.model .input_items input[type="file"] {
	display: none;
}
.model .input_items .img_box i {
	font-size: 50px;
	cursor: pointer;
}

.retweet_model {
	margin-top: 15px;
	margin-bottom: 15px;
}
.retweet_model .retweet_user {
	display: flex;
	margin-bottom: 15px;
}
.retweet_model .retweet_user a {
	color: unset !important;
}
.retweet_model .retweet_user > div {
	margin-top: auto;
	margin-bottom: auto;
	margin-right: 5px;
}
.retweet_model .retweet_user .img img {
	width: 49px;
	height: 49px;
	border-radius: 50%;
}
.retweet_model textarea {
	padding-top: 15px;
	border: none;
	outline: none;
	width: 100%;

	font-weight: 500;
	font-size: 20px;
	line-height: 25px;
	color: var(--title_color);
	margin-top: 10px;
	margin-bottom: 10px;
}
.retweet_model .retweet_content .title h2 {
	font-weight: 500;
	font-size: 20px;
	line-height: 25px;
	color: var(--title_color);
	margin-top: 10px;
	margin-bottom: 10px;
}
.retweet_model .retweet_content .description {
	font-weight: 400;
	font-size: 16px;
	line-height: 25px;
	color: var(--content_color);
	margin-bottom: 15px;
}

.retweet_model .retweet_content img {
	max-height: 300px;
	max-width: 100%;
}

.global_scroll {
	overflow-y: auto;
}

.global_scroll::-webkit-scrollbar {
	width: 5px;
	border-radius: 10px;
	cursor: pointer;
}

.global_scroll::-webkit-scrollbar-track {
	box-shadow: inset 10px 10px 10px #eaeaea;
	cursor: pointer;
}

.global_scroll::-webkit-scrollbar-thumb {
	border: 4px solid transparent;
	border-radius: 8px;
	background-color: var(--btn_color);
	cursor: pointer;
}

@media screen and (max-width: 991px) {
	.model .form_main .form {
		top: 50%;
	}
}
@media screen and (max-width: 750px) {
	.model .form_main .form {
		padding: 30px 20px;
	}
	.model .form_main .form {
		width: 80%;
	}
	.model .form_main .form .input_items input,
	.model .form_main .form .input_items select {
		width: 100%;
	}
}
@media screen and (max-width: 500px) {
	.model .form_main .form {
		width: 90%;
	}
	.model .form_main .form_header p {
		font-size: 15px;
	}
	.model .form_main .form .input_items label {
		margin-bottom: 3px;
		font-size: 13px;
	}
}
