.glory .title {
	margin-bottom: 10px;
}
.glory .glory_sub {
	margin-bottom: 10px;
	font-size: 20px;
}
.glory_btn {
	background-color: var(--btn_color);
	border: var(--btn_color);
	color: #fff !important;
	width: max-content;
}
table .gain_percentage {
	color: #45fc31 !important;
}
table .gain_percentage.negetive {
	color: red !important;
}

table td.white,
table td.white:hover {
	background-color: #ccc !important;
	box-shadow: unset !important;
	border: unset;
}

.leaderboard th,
.leaderboard td {
	text-align: center;
}
