.about_box {
	background: #ffffff;
	border-radius: 5px;
	padding: 30px;
	margin-bottom: 30px;
}
.about_box .about_banner img {
	max-width: 100%;
}
.about_h3 {
	font-size: 24px;
	line-height: 36px;
	color: #383838;
	margin-top: 21px;
}
.about_description p {
	margin-top: 5px;
	font-weight: 400;
	font-size: 16px;
	line-height: 25px;
	color: #7c7c7c;
	margin-top: 20px;
}
.about_description .center {
	text-align: center;
}
.rule_row p {
	margin-top: 5px;
	font-weight: 400;
	font-size: 16px;
	line-height: 25px;
	color: #7c7c7c;
	margin-left: 20px;
	white-space: pre-wrap;
}
.about_box_p p span {
	font-weight: bold;
}
.about_box .rule_row {
	position: relative;
}
.about_box .rule_row .box {
	background: #1877f2;
	transform: rotate(-45deg);
	width: 7.96px;
	height: 7.96px;
	margin-top: auto;
	margin-bottom: auto;
	margin-right: 10px;
	position: absolute;
	top: 9px;
}
.member_box {
	display: flex;
	flex-wrap: wrap;
}
.member_box_item {
	background: #1877f2;
	border-radius: 16.4902px;
	padding-top: 54px;
	padding-bottom: 25px;
	width: 48%;
	margin-top: 20px;
	position: relative;
	min-height: 560px;
}
.member_box_item:nth-child(1) {
	margin-right: 20px;
}
.member_box_item.box2 {
	background: #ffa800;
}

.member_box_item.box2 h4 {
	background: #9a6600;
}
.member_box_item.box2 .member_button button a {
	color: #ffa800;
}
.member_box_item h4 {
	text-align: center;
	background: #00377e;
	border-radius: 8.24508px;
	padding: 10px 0px;
	color: #fff;
	width: 80%;
	margin: auto;
	font-weight: 500;
	font-size: 19.7882px;
	line-height: 110%;
}
.member_box_item {
	background: #1877f2;
	border-radius: 16.4902px;
	padding-top: 54px;
	padding-bottom: 25px;
}
.member_box_item .memeber_img {
	display: flex;
	margin-top: -85px;
}
.member_box_item .memeber_img .img {
	width: 100%;
}
.member_box_item .memeber_img .img:nth-child(2) {
	text-align: right;
	margin-top: auto;
}

.member_decription,
.member_button {
	padding-right: 25px;
	padding-left: 25px;
}
.member_decription_row {
	position: relative;
	margin-top: 28px;
	color: #fff;
}
.member_decription_row .img {
	position: absolute;
}
.member_decription_row .img img {
	border-radius: 50%;
	background: #fff;
	padding: 5px 4px;
}
.member_decription_row .content {
	margin-left: 33px;
}
.member_box_item .member_button {
	margin-top: 45px;
	text-align: center;
}
.member_box_item .member_button {
	position: absolute;
	bottom: 20px;
	left: 0px;
	right: 0px;
}

.member_box_item .member_button button {
	background: #ffffff;
	border-radius: 6px;
	padding: 12px 15px;
	justify-content: center;
	font-size: 14px;
	line-height: 21px;
	text-align: center;
	color: #1877f2;
	font-family: "Poppins-Medium";
	outline: none;
	border: none;
}
.member_box_item .member_button button a {
	color: #1877f2;
}
.about_header {
	z-index: 1;
}
.about_bottom {
	border-top: 1px solid #f1f1f1;
	margin-top: 30px;
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
	.member_box_item {
		min-height: 605px;
	}

	.member_box_item .memeber_img .img:nth-child(2) {
		margin-left: -37px;
	}
}

@media screen and (max-width: 850px) {
	.member_box {
		display: block;
	}
	.member_box_item {
		width: 60%;
		margin-bottom: 20px;
	}
}
@media screen and (max-width: 700px) {
	.member_box_item {
		width: 70%;
	}
}
@media screen and (max-width: 602px) {
	.member_box_item {
		width: 100%;
	}
}
@media screen and (max-width: 620px) {
	.about_box {
		margin-top: 135px;
	}
}
@media screen and (max-width: 546px) {
	.member_box_item {
		max-width: 370px;
	}
	.member_decription_row .content p {
		font-size: 14px;
	}
	.member_box_item .member_button button {
		padding: 12px 30px;
	}
	.about_box {
		padding: 30px 15px;
	}
}
@media screen and (max-width: 506px) {
	.member_box_item .memeber_img .img {
		max-width: 50%;
	}
	.member_decription,
	.member_button {
		padding-right: 12px;
		padding-left: 12px;
	}
	.about_h3 {
		font-size: 20px;
	}
}
@media screen and (max-width: 406px) {
	.member_box_item .member_button button {
		font-size: 13px;
		padding: 12px 10px;
	}

	/* .memeber_img .img:nth-child(1) {
		width: 40%;
	} */
	.member_box_item .memeber_img {
		flex-wrap: wrap;
	}
	.member_box_item .memeber_img .img:nth-child(2) {
		margin-top: -50px;
		margin-bottom: unset;
		width: 100%;
		max-width: 100%;
		margin-top: -105px;
	}
	.member_box_item {
		min-height: 590px;
	}
}
@media screen and (max-width: 350px) {
	.contact_email {
		font-size: 14px;
	}
}
