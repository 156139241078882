.shape_wrapper {
	position: relative;
	min-height: 300px;
	background: #fff;
}
.shape_wrapper .content_item {
	padding-top: 100px;
	padding-bottom: 100px;
	padding-left: 150px;
	padding-right: 150px;
	z-index: 1;
	position: relative;
}
.shape_wrapper .content_item h1 {
	font-size: 36px;
}
.shape_wrapper .content_item p {
	margin-bottom: 10px;
}

.triangle_top {
	width: 200px;
	aspect-ratio: 1;
	clip-path: polygon(0 0, 100% 0, 0 100%);
	background: linear-gradient(45deg, #1877f2, #1877f2);
	position: absolute;
	left: 0px;
}
.triangle_bottom {
	width: 200px;
	aspect-ratio: 1;
	clip-path: polygon(0 100%, 100% 0, 100% 100%);
	background: linear-gradient(45deg, #ffa800, #ffa800);
	position: absolute;
	right: 0px;
	bottom: 0px;
}

@media screen and (max-width: 767px) {
	.shape_wrapper .content_item {
		padding-top: 50px;
		padding-bottom: 50px;
		padding-left: 100px;
		padding-right: 100px;
	}
}
@media screen and (max-width: 555px) {
	.shape_wrapper .content_item {
		padding-top: 30px;
		padding-bottom: 30px;
		padding-left: 50px;
		padding-right: 50px;
	}

	.triangle_top {
		width: 150px;
	}
	.triangle_bottom {
		width: 150px;
	}
}
@media screen and (max-width: 475px) {
	.shape_wrapper .content_item {
		padding-top: 15px;
		padding-bottom: 15px;
		padding-left: 25px;
		padding-right: 25px;
	}

	.triangle_top {
		width: 100px;
	}
	.triangle_bottom {
		width: 100px;
	}
}
